<template>
  <b-page class="g-page-list">

    <!-- 条件查询 -->
    <div style="display: flex; align-items: flex-end; justify-content: space-between;">
         <el-form label-position="top" ref="searchForm" :model="searchData">
           <el-form-item label="" prop="state">
             <span>状态：</span>
             <!-- 进行中，已完成，超时完成,超时未完成 -->
             <el-select
               v-model="searchData.parent_id"
               placeholder="请选择"
               style="width: 200px;"
               @change="change"
             >
               <el-option
                 label="进行中"
                 value="0"
               ></el-option>

               <el-option
                 label="已完成"
                 value="442"
               ></el-option>

             </el-select>
           </el-form-item>
         </el-form>
         <div class="g-mb-20" style="text-align: right;">
           <b-btn-create @click="onCreate"></b-btn-create>
           <b-btn-refresh @click="onRefresh"></b-btn-refresh>
         </div>
       </div>


    <!-- 表格 -->
    <el-table v-loading="loading" :data="list">
      <el-table-column label="标题" prop="title">
        <template slot-scope="{ row }">
          {{row.title}}
          <el-tag  type='danger' v-if="row.parent.id=='442'">已完成</el-tag>
          <el-tag effect="plain" v-else> {{ row.parent.title }}</el-tag>
        </template>
      </el-table-column>
     <el-table-column label="发布人" prop="department.name" width="160">
       <template slot-scope="{ row }">
         {{row.department.name}}({{row.create_user.name}})
       </template>
     </el-table-column>
     <el-table-column sortable label="发布时间" prop="created_at" width="140"></el-table-column>

      <el-table-column sortable label="更新日期" prop="updated_at" width="140"></el-table-column>
      <el-table-column label="操作" width="160" align="center">
        <template slot-scope="{ row }">
          <c-btn-table
            :show="['info', currentPageType == 'my' ? 'update' : '', currentPageType == 'my' ? 'delete' : '']"
            @info="onInfo(row)"
            @update="onUpdate(row)"
            @delete="onDelete(row)"
          ></c-btn-table>
        </template>
      </el-table-column>
    </el-table>

    <b-pagination :page="page" @change="fetchList"></b-pagination>

    <form-detail ref="detail" @success="onRefresh"></form-detail>
  </b-page>
</template>

<script>
import { deleteSpecial, getSpecialPage, getSpecialAttentionPage, getSpecial } from '@/api/special'
import FormDetail from '../form-detail.vue'
export default {
  components: {
    FormDetail,
  },
  data() {
    return {
      currentPageType: null,
      loading: false,
      searchData: {
        title: '',
         parent_id:""
      },
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      list: [],
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
    deptId() {
      return this.$store.getters['user/deptId']
    },
  },
  watch: {
    '$route.path': {
      handler(val) {
        // my、attention、receive、create
        this.currentPageType = val.split('/').slice(-1)[0]
        this.fetchList()
      },
      immediate: true,
    },
  },
  methods: {
    change(){
        this.page.current=0;
       this.fetchList()
    },

    fetchList() {
      const params = {
        page: this.page.current,
        parent_id:this.searchData.parent_id||0,
      }

      this.loading = true
      Promise.resolve()
        .then(() => {
          if (this.currentPageType === 'attention') {
            params.user_id = this.userId
            return getSpecialAttentionPage(params)
          } else {
            if (this.currentPageType === 'my') {
              params.create_by = this.userId
            } else if (this.currentPageType === 'create') {
              params.department_id = this.deptId
            }
            return getSpecialPage(params)
          }
        })
        .then(res => {
          this.list = res.data
          this.page.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    onRefresh() {
      this.page.current = 1
      this.fetchList()
    },
    onCreate() {
      this.$refs.detail.open({
        title: '',
        content: '',
        departments: [this.deptId],
        users: [],
      })
    },
    onUpdate(row) {
      getSpecial(row.id).then(res => {
        this.$refs.detail.open({
          id: res.id,
          title: res.title,
          parent_id: res.parent_id,
          content: res.content,
          departments: res.accept_departments.map(item => item.id),
          users: res.follow_users.map(item => item.id),
        })
      })
    },
    onDelete(row) {
      this.$confirm('确定删除当前项吗？', '提示', { type: 'warning' })
        .then(() => {
          return deleteSpecial(row.id)
        })
        .then(() => {
          this.$message.success('删除成功')
          this.onRefresh()
        })
        .catch(() => {})
    },
    onInfo(row) {
      this.$router.push({ path: '/special/detail', query: { id: row.id } })
    },
  },
}
</script>
